import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { baseURL } from './apiConstant'
import _ from 'lodash';
import { useSelector } from 'react-redux'
import { OPEN_SNACKBAR } from 'app/redux/actions/snackBarActions'
import useAuth from 'app/hooks/useAuth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}





const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    return true

    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'ERROR': {
            const { error } = action.payload

            return {
                ...state,
                isAuthenticated: false,
                error,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        try {
            const response = await axios.post(baseURL + 'login', {
                email,
                password,
            })

            const { token } = response.data.data
            setSession(token)
            toast.success(response?.data?.Message)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: response.data.data,
                },
            })
        } catch (error) {
            toast.error(error?.response?.data?.Message)
            dispatch({
                type: 'ERROR',
                payload: {
                    error: error.response.data.Message,
                },
            })

        }
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })
        console.log(response, 'response')
        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }
    // let {
    //     user
    // } = useAuth();
    useEffect(() => {
        ; (async () => {
            try {

                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {

                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2000}
            />
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
