import { useDispatch } from "react-redux";
import { Store } from "../Store";

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const ShowSuccessSnackBar = (message) => {
    Store.dispatch({ type: OPEN_SNACKBAR, payload: { isOpen: true, type: 'success', message: message } })
};

export const showErrorSnackBar = (message) => {
    Store.dispatch({ type: OPEN_SNACKBAR, payload: { isOpen: true, type: 'error', message: message } })
};
export const showInfoSnackBar = (message) => {
    Store.dispatch({ type: OPEN_SNACKBAR, payload: { isOpen: true, type: 'info', message: message } })
};
export const showWarningSnackBar = (message) => {
    Store.dispatch({ type: OPEN_SNACKBAR, payload: { isOpen: true, type: 'warning', message: message } })
};
