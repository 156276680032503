const { OPEN_SNACKBAR, CLOSE_SNACKBAR } = require("../actions/snackBarActions");

const initialState = {
    isOpen: false,
    message: '',
    type: '' || 'success'
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SNACKBAR:
            return action.payload
        case CLOSE_SNACKBAR:
            return { ...state, isOpen: false, message: '', type: 'success' }
        default:
            return state
    }
}
export default snackbarReducer;
