import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const EmailTemplate = Loadable(lazy(() => import('./emailTemplate/templateList')));
const EmailTemplateStore = Loadable(lazy(() => import('./emailTemplate/templateCreate')));
const EmailView = Loadable(lazy(() => import('./emailTemplate/view')));
const CMSMangement = Loadable(lazy(() => import('./cmsManagement/listCms')));
const CMSMangementStore = Loadable(lazy(() => import('./cmsManagement/createCms')));
const SubscriptionList = Loadable(lazy(() => import('./subscriptionPlan/subscriptionList')));
const SubscriptionStore = Loadable(lazy(() => import('./subscriptionPlan/subscriptionCreate')));
// User Mangement
const FanList = Loadable(lazy(() => import('./userMangement/fan/fanList')));
const EditFan = Loadable(lazy(() => import('./userMangement/fan/editFan')));
const ViewFan = Loadable(lazy(() => import('./userMangement/fan/viewFan')));
const CreatorList = Loadable(lazy(() => import('./userMangement/creator/creatorList')));
const EditCreator = Loadable(lazy(() => import('./userMangement/creator/editCreator')));
const ViewCreator = Loadable(lazy(() => import('./userMangement/creator/viewCreator')));
const ChangePassword = Loadable(lazy(() => import('./profileManagement/changePassword')));
const UserProfile = Loadable(lazy(() => import('./profileManagement/profile')));
const Post = Loadable(lazy(() => import('./postManagement/postList')))
const PostView = Loadable(lazy(() => import('./postManagement/postView')));
const GlobalSettings = Loadable(lazy(() => import('./globalSettings/globalSetting')));
const ListCategory = Loadable(lazy(() => import('./categoryManagement/CategoryList')));
const CreateCategory = Loadable(lazy(() => import('./categoryManagement/CreateCategory')));
const ViewCategory = Loadable(lazy(() => import('./categoryManagement/ViewCategory')));
const EditCategory = Loadable(lazy(() => import('./categoryManagement/EditCategory')));
const AttributeList = Loadable(lazy(() => import('./attributeManagement/AttributeList')));
const CreateAttribute = Loadable(lazy(() => import('./attributeManagement/CreateAttribute')));
const EditAttribute = Loadable(lazy(() => import('./attributeManagement/EditAttribute')));
const ViewAttribute = Loadable(lazy(() => import('./attributeManagement/ViewAttribute')));
const adminRoutes = [
    { path: '/admin/email-template', element: <EmailTemplate />, auth: authRoles.admin },
    { path: '/admin/email-template/create', element: <EmailTemplateStore />, auth: authRoles.admin },
    { path: '/admin/email-template/view', element: <EmailView />, auth: authRoles.admin },
    { path: '/admin/cms-management', element: <CMSMangement />, auth: authRoles.admin },
    { path: '/admin/cms-management/create', element: <CMSMangementStore />, auth: authRoles.admin },
    { path: '/admin/subscription', element: <SubscriptionList />, auth: authRoles.admin },
    { path: '/admin/subscription/create', element: <SubscriptionStore />, auth: authRoles.admin },
    { path: '/admin/user/fan', element: <FanList />, auth: authRoles.admin },
    { path: '/admin/user/creator', element: <CreatorList />, auth: authRoles.admin },
    { path: '/admin/user/editcreator/:id', element: <EditCreator />, auth: authRoles.admin },
    { path: '/admin/user/viewcreator', element: <ViewCreator />, auth: authRoles.admin },
    { path: '/admin/user/editfan/:id', element: <EditFan />, auth: authRoles.admin },
    { path: '/admin/user/viewfan', element: <ViewFan />, auth: authRoles.admin },
    { path: '/admin/user/changepassword', element: <ChangePassword />, auth: authRoles.admin },
    { path: '/user-profile', element: <UserProfile />, auth: authRoles.admin },
    { path: '/admin/post', element: <Post />, auth: authRoles.admin },
    { path: '/admin/post/view', element: <PostView />, auth: authRoles.admin },
    
    { path: '/admin/global-settings', element: <GlobalSettings />, auth: authRoles.admin},

    { path: '/admin/category', element: <ListCategory />, auth: authRoles.admin},
    { path: '/admin/category/create', element: <CreateCategory />, auth: authRoles.admin},
    { path: '/admin/category/view', element: <ViewCategory />, auth: authRoles.admin},
    { path: '/admin/category/edit/:id', element: <EditCategory />, auth: authRoles.admin},

    { path: '/admin/attribute', element: <AttributeList />, auth: authRoles.admin},
    { path: '/admin/attribute/create', element: <CreateAttribute />, auth: authRoles.admin},
    { path: '/admin/attribute/edit/:id', element: <EditAttribute />, auth: authRoles.admin},
    { path: '/admin/attribute/view', element: <ViewAttribute />, auth: authRoles.admin},
];

export default adminRoutes;
