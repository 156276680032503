// live
// export const baseURL = 'https://tedallal.devtechnosys.tech/api/';

// stateging
// export const baseURL = 'https://cuminu.devtechnosys.tech/';

// local
// export const baseURL = 'http://localhost:5001/admin/';
// export const imageURL = 'http://localhost:5001/';
export const baseURL = 'https://cuminu.devtechnosys.tech:17372/admin/';
export const imageURL = 'https://cuminu.devtechnosys.tech:17372/';

export const ApiURL = {
    register: 'register',
    login: 'admin/login'
};

