import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
// import useFetch, { Provider as SecondProvider } from 'use-http'
import CustomizedSnackbars from './views/material-kit/snackbar/CustomizedSnackbar';

const App = () => {
  const content = useRoutes(routes);
  // const options = {
  //   headers: {
  //     Accept: 'application/json'
  //   }
  // }
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
          <CustomizedSnackbars />
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
